<template>
  <div class="title-next-page__container"
       v-if="activeItemAside !== null || activeItemAside < list.length - 1 "
       @mouseover="onHover"
       @mouseout="offHover"
  >
    <router-link :to="{path:list[activeItemAside +1].path}"
                 @click.native="offHover; clickNavLink(list[activeItemAside].index)">
      <div class="title-next-page__next title-next-page__next_visible" v-if="langPhrases">
        <p>{{langPhrases.menu[list[activeItemAside + 1].code]  }}</p>
      </div>
      <div class="title-next-page__next title-next-page__next_hidden" v-if="langPhrases">
        <p>{{ langPhrases.menu[list[activeItemAside + 1].code] }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'TitleNextPage',
  components: {},
  data() {
    return {
      width: 0,
    }
  },
  computed: {
    activeItemAside() {
      return this.$store.getters.ACTIVE_NAV_LINK;
    },
    list() {
      return this.$store.getters.LIST;
    },
    langPhrases(){
      return this.$store.getters.LANG_PHRASES;
    },
  },
  watch: {},
  mounted() {
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    changeTheme() {
      if (this.theme) {
        this.$store.dispatch('changeTheme', false)
        this.$store.dispatch('changeThemeTitle', 'White')
      } else if (!this.theme) {
        this.$store.dispatch('changeTheme', true)
        this.$store.dispatch('changeThemeTitle', 'Black')
      }
    },
    onHover() {
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.add('cursor_hover');

    },
    offHover() {
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.remove('cursor_hover');
    },
    clickNavLink(index) {
      this.$store.dispatch('clickNavLink', index)
    },
  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';


.title-next-page {

  &__container {
    display: flex;
    flex-direction: column;
    @extend %padding-right;
    @extend %padding-left;
    padding-bottom: 52px;
    transition: all .6s ease;
    overflow: hidden;
    max-height: 350px;
    height: 100%;
    margin-top: auto;
    margin-bottom: 0;

    & a {
      position: relative;
      height: 100%;
      //  ====
      min-height: 300px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

    }


  }

  &__next {
    @extend %font;
    display: flex;
    align-items: baseline;
    font-size: 320px;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-text);
    transition: all .6s ease;
    width: 100%;
    position: absolute;
    opacity: .05;

    & p {
      margin: 0;
      padding-top: 12px;
    }

    & span {
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      opacity: .3;
      margin-right: 0;
      margin-left: auto;
    }

  }

  &__next_visible {
    top: 0;
    left: 0;
    transition: all .6s ease;
  }

  &__next_hidden {
    top: 100%;
    left: 0;

  }
}

@media only screen and (max-width: 1680px) {
  .title-next-page {
    &__container {
      max-height: 226px;

    }

    &__next {
      font-size: 17vw;
    }
  }

}


@media only screen and (max-width: 1760px ){
  .title-next-page {
    &__container{
      font-size: 14em;
    }
  }
}
@media only screen and (max-width: 1180px) {
  .title-next-page  {
    &__container {
      font-size: 120px;
      max-height: 114px;
    }
  }
}

@media only screen and (min-width: 980px){
  .title-next-page{
    &__container{
      &:hover {
        .title-next-page__next {
          opacity: 1;
          transition: all .6s ease;

          & span {
            opacity: 1;
          }
        }

        .title-next-page__next_visible {
          top: -100%;
        }

        .title-next-page__next_hidden {
          top: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 720px) {
  .title-next-page  {
    &__container{
      font-size: 64px;
    }
  }
}


@media only screen and (max-width: 490px) {
  .title-next-page {
    &__container {
      max-height: 64px;
    }

    &__next {
      font-size: 64px;
    }
  }
}

</style>
