<template>
  <div class="agency">
    <Aside
        @change-theme="changeTheme"/>
    <div class="agency__container">
      <div class="agency__title-container" v-if="langPhrases">
        <Title/>
        <div class="agency__title-text" v-show="width>1580">{{ langPhrases.page.agency.subtitle }}
        </div>
        <div class="agency__elephant">
          <!--          <Elephant/>-->
          <img src="../assets/images/elephant_xl.png" alt="" class="elephant-img"
               v-if="safari && width>=1321">
          <video class="elephant-webm" autoplay loop muted playsinline
                 v-else-if="width>=1321 ">
            <source src="../assets/video/elephant_xl.webm" type="video/webm">
          </video>
          <video class="elephant-webm" autoplay loop muted playsinline
                 v-else-if="!safari && width < 1321 ">
            <source src="../assets/video/elephant_l.webm" type="video/webm">
          </video>
          <img src="../assets/images/elephant_m.png" alt="" class="elephant-img"
               v-else-if="width < 1321">
          <div class="agency__title-text" v-show="width<=1580">{{ langPhrases.page.agency.subtitle }}</div>
          <a :href="langPhrases.page.agency.presentation.file" target="_blank" class="agency__presentation">
            <span class="agency__presentation-bg"></span>
            <p class="agency__presentation-title">{{ langPhrases.page.agency.presentation.title }}</p>
            <p class="agency__presentation-subtitle">{{ langPhrases.page.agency.presentation.subtitle }}</p>
          </a>
        </div>
      </div>
      <div class="agency__subtitle-container">
        <div class="agency__subtitle" v-if="langPhrases">
          {{ langPhrases.page.agency.text1 }}
        </div>
        <div class="agency__subtitle-text" v-if="langPhrases">{{ langPhrases.page.agency.text2 }}</div>
      </div>
      <div class="agency__about-container"
           v-if="scroll">
        <div class="agency__about-year" v-if="langPhrases">
          <animated-number
              class="agency__about-count"
              :value="`${langPhrases.page.agency.indicator.age.value}`"
              :duration="2000"
              :round="1"
              :delay="1100"
          />
          <span class="agency__about-count-title" v-if="langPhrases">{{ langPhrases.page.agency.indicator.age.caption }}</span>
        </div>
        <div class="agency__about-employees" v-if="langPhrases">
          <animated-number
              class="agency__about-count"
              :value="`${langPhrases.page.agency.indicator.employees.value}`"
              :duration="2000"
              :round="1"
              :delay="1100"
          />
          <span class="agency__about-count-title" v-if="langPhrases">{{ langPhrases.page.agency.indicator.employees.caption }}</span>
        </div>
      </div>
      <div class="agency__competence">
        <Competence/>
      </div>
      <div class="agency__subtitle-container agency__subtitle-container_bottom" v-if="langPhrases">
        <div class="agency__subtitle" v-html="langPhrases.page.agency.text3">
          <!--          {{ langPhrases.page.agency.text3 }}-->
        </div>
      </div>
      <TitleNextPage/>
    </div>
  </div>
</template>

<script>
import Aside from '@/components/Aside.vue';
import Competence from '@/components/Competence .vue';
// import Elephant from '@/components/Elephant.vue';
import TitleNextPage from '@/components/TitleNextPage.vue';
import Title from '@/components/Title.vue';
import AnimatedNumber from "animated-number-vue";

export default {
  name: 'Agency',
  metaInfo() {
    if(this.langPhrases) {
      return {
        title: this.langPhrases.seo.agency.title,
        meta: [
          {name: 'description', content: this.langPhrases.seo.agency.description},
        ]
      }
    }
  },
  components: {
    Aside,
    AnimatedNumber,
    Competence,
    // Elephant,
    TitleNextPage,
    Title,
  },
  data() {
    return {
      width: 0,
      safari: /apple/i.test(window.navigator.vendor),
      colorTextThemeWhite: '#1F2123',
      backgroundThemeWhite: '#F5F5F5',
      backgroundThemeBlack: '#1F2123',
      colorTextThemeBlack: '#ffffff',
      years: 12,
      employees: 26,
      scroll: false,

    }
  },
  computed: {
    isItemHovered() {
      return this.$store.getters.IS_ITEM_HOVERED;
    },
    isItemsHover() {
      return this.$store.getters.IS_ITEMS_HOVER;
    },
    isItemIndexHovered() {
      return this.$store.getters.IS_ITEM_INDEX_HOVERED;
    },
    theme() {
      return this.$store.getters.THEME;
    },
    activeItemAside() {
      return this.$store.getters.ACTIVE_NAV_LINK;
    },
    list() {
      return this.$store.getters.LIST;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },

  },
  watch: {},
  created() {
    window.addEventListener('scroll', this.handleScroll);

  },
  mounted() {
    this.scrollTop();
    this.$store.dispatch('clickNavLink', 1);
    this.mouseHover();
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    // isSafari() {
    //   if (navigator.userAgent.indexOf("Safari") != -1) {
    //     this.safari = true;
    //   }
    // },
    changeTheme(theme) {
      if (theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeBlack)
        document.body.style.setProperty('--background', this.backgroundThemeBlack)
      } else if (!theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeWhite)
        document.body.style.setProperty('--background', this.backgroundThemeWhite)
      }
    },
    onHover() {
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.add('cursor_hover');

    },
    offHover() {
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.remove('cursor_hover');
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    mouseHover() {
      if (!this.isItemHovered) {
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover');
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
        document.querySelector('.cursor').classList.remove('cursor_hover')
      } else if (this.isItemHovered) {
        document.querySelector('.cursor-dot').classList.add('cursor-dot_hover')
        document.querySelector('.cursor').classList.add('cursor_hover')

      }
    },
    handleScroll() {
      if (window.scrollY > 60) {
        this.scroll = true;
      }
    },
    clickNavLink(index) {
      this.$store.dispatch('mouseOut', null)
      this.$store.dispatch('clickNavLink', index)
    },
    onResize() {
      this.width = window.innerWidth;
    },
  },

}
</script>
<style lang="scss" scoped>
@import '../assets/styles/vars.scss';

.agency {
  display: flex;
  background-color: var(--background);

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    @extend %max-width;
    @extend %padding-right;
    overflow: hidden;
  }

  &__title-container {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    //margin-bottom: 78px;
  }

  &__elephant {
    @extend %padding-left;
    display: flex;
    position: relative;
    height: 820px;
    //max-width: 1500px;
    width: 100%;
    //align-self: center;
    justify-content: center;
    //overflow: hidden;
    //top: -90px;

    max-width: 1128px;
    width: 100%;
    max-height: 792px;
    //height: 100%;
    margin-bottom: 60px;
    background-size: cover;
    flex-wrap: wrap;
  }

  &__title-text {
    @extend %fontInter;
    color: var(--color-text);
    font-weight: 500;
    font-size: 24px;
    max-width: 299px;
    line-height: 144%;
    margin-right: 0;
    margin-left: auto;

    z-index: 3;
  }

  &__presentation {
    position: absolute;
    bottom: 8%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: var(--background-btn-theme-white);
    transition: all .4s linear;
    //margin-right: 60px;
    margin-left: auto;
    margin-right: 0;
  }

  &__presentation-bg {
    background: #B3BCC1;
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 50%;
    z-index: 0;
    transition: all .6s ease;
  }

  &__presentation-title, &__presentation-subtitle {
    margin: 0;
    z-index: 1;
    transition: all .6s ease;

  }

  &__presentation-title {
    @extend %font;
    font-size: 60px;
    font-weight: 700;
    color: var(--color-text-theme-white);


  }

  &__presentation-subtitle {
    @extend %fontInter;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text-theme-white);

  }

  &__subtitle-container {
    @extend %padding-left;
    margin-bottom: 134px;
  }

  &__subtitle-container_bottom {
    margin-bottom: 200px;

    .agency__subtitle {
      max-width: 1227px;
      margin-bottom: 0;
    }
  }

  &__subtitle {
    @extend %font;
    font-size: 112px;
    color: var(--color-text);
    text-transform: uppercase;
    max-width: 767px;
    margin-bottom: 106px;
  }

  &__subtitle-text {
    @extend %fontInter;
    color: var(--color-text);
    max-width: 444px;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 166%;
    margin-right: 460px;
    margin-left: auto;
  }

  &__about-container {
    @extend %padding-left;
    display: flex;
    width: 100%;
    //padding-bottom: 96px;
    margin-bottom: 96px;
  }

  &__about-year, &__about-employees {
    @extend %font;
    color: var(--color-text);
  }

  &__about-count {
    display: inline-block;
    font-size: 320px;
    width: 216px;
  }

  &__about-count-title {
    font-size: 32px;
    text-transform: uppercase;
  }

  &__about-year {
    margin-right: 200px;
  }

  &__competence {
    display: flex;
    //width: 100%;
    align-items: center;
    @extend %padding-left;
    margin-bottom: 152px;
    //overflow: hidden;
  }

  &__next-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    @extend %padding-right;
    @extend %padding-left;
    padding-bottom: 52px;

    transition: all .6s ease;
    overflow: hidden;
    max-height: 280px;
    height: 100%;

    & a {
      position: relative;
      height: 100%;
      //  ====
      min-height: 350px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

    }

    &:hover {
      .agency__next {
        opacity: 1;
        transition: all .6s ease;

        & span {
          opacity: 1;
        }
      }

      .agency__next_visible {
        top: -100%;
      }

      .agency__next_hidden {
        top: 0;
      }
    }
  }

  &__next {
    @extend %font;
    display: flex;
    align-items: baseline;
    font-size: 320px;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-text);
    transition: all .6s ease;
    width: 100%;
    position: absolute;
    opacity: .05;

    & p {
      margin: 0;
      padding-top: 12px;
    }

    & span {
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      opacity: .3;
      margin-right: 0;
      margin-left: auto;
    }

  }

  &__next_visible {
    top: 0;
    left: 0;
    transition: all .6s ease;
  }

  &__next_hidden {
    top: 100%;
    left: 0;

  }
}

.elephant-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.elephant-webm {
  width: 100%;
  //height: 100%;
  object-fit: contain;
  position: absolute;
  top: -60%;
  left: 10%;
  right: 0;
  bottom: 0;
}

@media only screen and(max-width: 1580px) {
  .agency {
    &__title-text {
      //order: 2;
      margin-left: 0;
      margin-right: auto;
      //transform: translateY(-102px);
      //padding-left: 120px;

      padding-top: 56px;
      z-index: 3;
    }

    &__subtitle-text {
      margin-right: auto;
      padding-left: 60px;
    }

    &__about-year {
      margin-right: 120px;
    }

    &__about-count {
      font-size: 220px;
      width: 160px;
    }

    &__about-count-title {
      font-size: 22px;
    }

    &__presentation {
      width: 182px;
      height: 182px;
    }

    &__elephant {
      max-height: 49vw;
    }
  }
  .elephant-webm {
    top: -50%;
    transform: scale(1.1);
  }
}

@media only screen and(max-width: 1320px) {
  .agency {
    &__elephant {
      max-width: 600px;
      max-height: 820px;
    }

    &__title-text {
      order: 2;
    }

    &__presentation {
      bottom: 0;
    }
  }
  .elephant-img {
    max-height: 654px;
  }
  .elephant-webm {
    top: -8%;
    transform: scale(1.7);
  }

}

@media only screen and(max-width: 1180px) {
  .agency {
    &__elephant {
      top: -20px;
      margin-bottom: 90px;
    }

    &__title-text {
      max-width: 248px;
    }
  }


}

@media only screen and(max-width: 980px) {
  .agency {
    &__title-text {
      padding-left: 42px;
      font-size: 20px;
    }

    &__presentation {
      bottom: 10%;
    }

    &__presentation-title {
      font-size: 32px;
    }

    &__presentation-subtitle {
      font-size: 13px;
    }

    &__subtitle {
      font-size: 64px;
      max-width: 438px;
      margin-bottom: 56px;
    }

    &__subtitle-container {
      margin-bottom: 64px;
    }

    &__subtitle-text {
      font-size: 13px;
      max-width: 192px;

    }

    &__about-container {
      margin-bottom: 70px;
    }

    &__about-count {
      font-size: 64px;
      width: 60px;
    }

    &__about-count-title {
      font-size: 16px;
    }

    &__about-year {
      margin-right: 120px;
    }
  }
  .elephant-webm {
    position: relative;
    top: -9%;
    left: 0;
    transform: scale(1.75);
  }
}

@media only screen and(min-width: 650px) {
  .agency {
    &__presentation {
      &:hover {
        .agency__presentation-bg {
          width: 100%;
          height: 100%;
          transition: all .4s linear;
        }

        .agency__presentation-title, .agency__presentation-subtitle {
          color: var(--color-text);
          transition: all .4s linear;
        }
      }
    }
  }
}

@media only screen and(max-width: 650px) {
  .agency {
    &__presentation {
      position: initial;
      order: 2;
      margin: 0 auto;
    }

    &__title-text {
      padding-left: 0;
      margin-left: 0;
      margin-right: 100px;
      margin-bottom: 20px;
    }

    &__subtitle {
      font-size: 44px;
    }
  }
  .elephant-img {
    max-height: 480px;
  }
}

@media only screen and(max-width: 490px) {
  .agency {
    &__title-text {
      padding-left: 26px;
      font-size: 16px;
      max-width: 252px;
      margin-bottom: 0;
      margin-right: 0;
    }

    &__presentation {
      width: 146px;
      height: 146px;
    }

    &__elephant {
      margin-bottom: 0;
    }

    &__about-count {
      font-size: 44px;
      width: 40px;
    }

    &__about-container {
      flex-direction: column;
      margin-right: auto;
      margin-left: auto;
      padding-left: 74px;
      width: 252px;
      margin-bottom: 60px;
    }

    &__about-year {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &__subtitle-text {
      padding: 0;
    }
  }
  .elephant-webm {
    position: relative;
    top: -7%;
    left: 0;
    transform: scale(1.9);
  }
}

</style>
