<template>
  <div class="competence">
    <div class="competence__title-container">
      <p class="competence__main-title" v-if="langPhrases">{{ langPhrases.page.agency.competenciesTitle }}</p>
      <p class="competence__main-title_short" v-if="langPhrases">{{ langPhrases.page.agency.competenciesTitleShort }}</p>
    </div>
    <Hooper class="competence__list" ref="carousel"
            :settings=" slideOption"
    >
      <slide
          v-for="item in competence"
          :key="item.id"
      >
        <div class="competence__item-container">
          <div class="competence__item-next"
               @click="slideNext"
               @mouseover="slideHoverNext"
               @mouseout="offHover">
          </div>
          <div class="competence__item-prev"
               @click="slidePrev"
               @mouseover="slideHoverPrev"
               @mouseout="offHover"
          ></div>
          <img v-if="item.logo" :src="item.logo.src" alt="лого " class="competence__img"
               :class=" {'competence__img_white': !theme } ">
          <div class="competence__options-container">
            <div class="competence__title"><p>{{ item.title }}</p></div>
            <ul class="competence__options">
              <li class="competence__option"
                  v-for="item in item.text"
                  :key="item.id">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </slide>
      <hooper-pagination slot="hooper-addons" mode="fraction"></hooper-pagination>
    </Hooper>
  </div>
</template>

<script>
import {Hooper, Slide, Pagination as HooperPagination,} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'Competence',
  props: {},
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      mounted: false,
      width: 0,
      elem: null,
      slideOption: {
        initialSlide: 0,
        centerMode: false,
        infiniteScroll: false,
        mouseDrag: true,
        transition: 900,
        touchDrag: true,
        shortDrag: true,
        hoverPause: false,
        wheelControl: false,
        breakpoints: {
          2400: {
            itemsToShow: 3.5
          },
          1850: {
            itemsToShow: 3.1
          },
          1730: {
            itemsToShow: 2.8
          },
          1530: {
            itemsToShow: 2.2
          },
          1310: {
            itemsToShow: 1.8
          },

          860: {
            itemsToShow: 1.4
          },
          700: {
            itemsToShow: 1.2
          },
          0: {
            itemsToShow: 1
          }

        },
      },
    }
  },
  computed: {
    isItemsHover() {
      return this.$store.getters.IS_ITEMS_HOVER;
    },
    competence() {
      return this.$store.getters.COMPETENCE;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },
    theme() {
      return this.$store.getters.THEME
    },
    activeLang() {
      return this.$store.getters.ACTIVE_LANG;
    }
  },
  watch: {
    activeLang() {
      this.$store.dispatch('fetchCompetencies')
    }
  },
  created() {
    this.$store.dispatch('fetchCompetencies')
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideHoverNext() {
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover');
      document.querySelector('.cursor').classList.add('cursor_hover');
    },
    slideHoverPrev() {
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover_slide_prev');
      document.querySelector('.cursor').classList.add('cursor_hover');
    },
    offHover() {
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_slide_prev');
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover');
      document.querySelector('.cursor').classList.remove('cursor_hover');
    },
  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

a {
  text-decoration: none;
}

.competence {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1372px;
  width: 100%;
  padding-top: 140px;
  padding-bottom: 152px;
  border-bottom: 1px solid #393B3E;
  border-top: 1px solid #393B3E;
  //overflow: hidden;

  &__list {
    width: 100%;
    height: 100%;
  }

  &__item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 400px;
    width: 100%;
    height: 400px;
    padding: 52px 40px 28px;
    border: 1px solid #393B3E;
    background-color: var(--background);
    transition: all .6s ease;
    overflow: hidden;


  }

  &__img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  &__img_white {
    filter: invert(100%);
  }

  &__title-container {
    @extend %font;
    text-transform: uppercase;
    color: var(--color-text);
    font-size: 112px;
    margin: 0;
    margin-bottom: 100px;

    & p {
      margin: 0;
    }
  }

  &__main-title_short {
    display: none;
  }

  &__title {
    @extend %font;
    color: var(--color-text);
    font-size: 32px;

    & p {
      margin: 0;
    }
  }

  &__options-container {
    margin-bottom: 0;
    margin-top: auto;
    transition: all .4s linear;
    height: 80px;
  }

  .hooper-pagination {
    top: -234px;
    right: 70px;
    @extend %font;
    color: var(--color-text);
    font-size: 32px;
    display: flex;
    align-items: baseline;
    position: absolute;
  }

  &__options {
    padding: 0;
    padding-top: 24px;
    margin: 0;
    list-style: none;
    //height: 0;
    visibility: hidden;
    transition: all .4s ease;
    //transform: translateY(100%);
  }

  &__option {
    @extend %fontInter;
    font-size: 16px;
    color: var(--color-text);
    margin-bottom: 10px;
    margin-left: 0;
  }

  &__item-container {
    position: relative;
  }

  &__item-prev, &__item-next {
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 0;
    z-index: 1;
    width: 76px;
  }

  &__item-prev {
    left: 0;
  }

  &__item-next {
    right: 0;

  }
}

@media only screen and(max-width: 1650px) {
  .competence {
    &__main-title {
      display: none;
    }

    &__main-title_short {
      display: initial;
    }
  }
}

@media only screen and(max-width: 1400px) {
  .competence {
    .hooper-pagination {
      font-size: 16px;
      top: -196px;
      right: 26px;

      & span:first-child {
        font-size: 54px;
        margin-right: 20px;
      }
    }

    &__item-container {
      max-width: 324px;
      height: 320px;
    }

    &__img {
      width: 64px;
      height: 64px;
    }

    &__title {
      font-size: 20px;
    }

    &__option {
      font-size: 13px;
    }
  }
}

@media only screen and(max-width: 1270px) {
  .competence {
    padding-top: 50px;
    padding-bottom: 80px;

    &__title-container {
      font-size: 64px;
      margin-bottom: 50px;
    }

    .hooper-pagination {
      top: -120px;
    }
  }
}

@media only screen and(min-width: 980px) {
  .competence {
    &__item-container {
      &:hover {
        filter: invert(100%);
        transition: all .4s linear;

        .competence__options-container {
          height: 200px;
          transition: all .4s linear;
        }

        .competence__options {
          height: auto;
          transition: all .4s linear;
          visibility: visible;

        }
      }
    }

  }
}

@media only screen and(max-width: 980px) {
  .competence {
    &__item-container {
      padding: 34px;

    }

    &__options {
      height: auto;
      transition: all .4s linear;
      transform: translate(0);
      visibility: visible;

    }

    &__options-container {
      height: auto;
      margin-top: 40px;
      margin-bottom: auto;
    }
  }
}

@media only screen and (max-width: 650px) {
  .competence {
    &__title-container {
      font-size: 44px;
    }

    .hooper-pagination {
      top: -100px;
    }
  }

}

@media only screen and(max-width: 490px) {
  .competence {
    &__item-container {
      max-width: 240px;
      height: 240px;
    }

    &__title {
      font-size: 16px;
    }

    &__img {
      width: 40px;
      height: 40px;
    }
  }
}
</style>

<style lang="scss">
.hooper-pagination span:first-child {
  font-size: 112px;
  margin-right: 70px;
}

@media screen and(max-width: 1400px) {
  .competence {
    .hooper-pagination {
      font-size: 16px;

      & span:first-child {
        font-size: 64px;
        margin-right: 20px;
      }
    }

  }
}

@media screen and (max-width: 650px) {
  .competence {
    .hooper-pagination span:first-child {
      font-size: 44px;
      margin-right: 14px;
    }
  }
}
</style>