<template>
  <div class="cases-description">
    <div class="cases-description__overlay"
         @click="hideDescription"></div>
    <div class="cases-description__container">
      <div class="cases-description__back">
        <CaseDescriptionBack/>
      </div>
      <div class="cases-description__wrap">
        <div class="cases-description__title-container">
          <div class="cases-description__title">{{ caseDescription.title }}</div>
          <ul class="cases-description__tags">
            <li class="cases-description__tag"
                v-for="item in caseDescription.tags"
                :key="item.id"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="cases-description__img-container"
             v-if="caseDescription.detailPicture ">
          <img class="cases-description__img"
               :src="caseDescription.detailPicture.src" alt="фото"
          >
        </div>
        <div class="cases-description__text-container">
          <div class="cases-description__text" v-html="caseDescription.detailText">
          </div>
          <div class="cases-description__text-about"
          v-if="caseDescription.indicators.length">
            <div class="cases-description__text-count"

                 v-for="item in caseDescription.indicators"
                 :key="item.id">
              <p>
                {{ item.value }}
                <span>{{ item.label }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="cases-description__subtitle">
          {{ caseDescription.subtitle }}
        </div>
        <div class="cases-description__additional-images">
          <img class="cases-description__img-additional"
               v-for="img in caseDescription.gallery"
               :key="img.index"
               :src="img.src" alt="фото"
          >
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CaseDescriptionBack from '@/components/CaseDescriptionBack.vue';

export default {


  name: 'CaseDescription',
  components: {
    CaseDescriptionBack,
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    theme() {
      return this.$store.getters.THEME;
    },
    caseDescription() {
      return this.$store.getters.CASE_DESCRIPTION;
    },
    activeLang() {
      return this.$store.getters.ACTIVE_LANG;
    },

  },
  watch: {},
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  activated() {
  },
  deactivated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  methods: {
    hideDescription() {
      this.$store.dispatch('showCaseDescription', false)
    },
  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

h2 {
  color: white;
  font-size: 70px;
}

.cases-description {
  position: absolute;
  width: 0;
  top: 0;
  right: 0;
  left: 100%;
  bottom: 0;
  transition: width 1s, left .7s ease;
  overflow: hidden;

  &_show {
    width: 100%;
    left: 0;
    transition: width .4s, left .6s ease;

    .cases-description__container {
      transform: translateX(0);
      transition: all .6s ease;
    }

  }

  &__overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 340;
    background-color: var(--background);
    opacity: .5;

  }

  &__container {
    transition: all .6s ease;
    transform: translateX(100%);
    //position: absolute;
    top: 0;
    right: 0;
    max-width: 886px;
    width: 100%;
    height: 100%;
    z-index: 999;
    padding-top: 52px;
    padding-left: 40px;
    @extend %padding-right;
    background-color: var(--background);


    position: fixed;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  &__img-back {
    margin-right: 20px;
    width: 24px;
    height: 22px;
  }

  &__back {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    cursor: default;
  }

  &__back-title {
    @extend %font;
    font-size: 32px;
    color: var(--color-text);
    opacity: .3;
    text-transform: uppercase;
  }

  &__title {
    @extend %font;
    color: var(--color-text);
    font-size: 60px;
    font-weight: 700;
  }

  &__tags {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__tag {
    @extend %fontInter;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text);
    margin-right: 16px;
    cursor: default;

    &:last-child {
      margin-right: 0;
    }
  }

  &__title-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__img-container {
    width: 100%;
    margin-bottom: 72px;
  }

  &__img {
    width: 100%;
    object-fit: cover;
  }

  &__text-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 82px;
    border-bottom: 1px solid #393B3E;
    margin-bottom: 56px;
  }

  &__text {
    //max-width: 50%;
    @extend %fontInter;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text);
    line-height: 150%;
    margin: 0;
  }

  &__text-about {
    display: flex;
    flex-direction: column;
    @extend %font;
    font-size: 60px;
    font-weight: 700;
    color: var(--color-text);

    max-width: 30%;
    width: 100%;

  }

  &__text-about p {
    margin: 0;
    line-height: 133%;
  }

  &__text-about span {
    @extend %fontInter;
    font-size: 16px;
    font-weight: 400;
  }

  &__subtitle {
    @extend %font;
    max-width: 90%;
    color: var(--color-text);
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    margin-bottom: 66px;
  }

  &__additional-images {
    display: flex;
    justify-content: space-between;
    //overflow-x: scroll;
  }

  &__img-additional {
    max-width: 48%;

    &:first-child {
      margin-right: 20px;
    }
  }

  &__wrap {
    padding-bottom: 80px;
  }

}

@media only screen and(max-width: 1600px) {
  .cases-description {
    &__container {
      max-width: 40%
    }
  }
}

@media only screen and(max-width: 1150px) {
  .cases-description {
    &__container {
      max-width: calc(100% - 400px)
    }
  }
}

@media only screen and(max-width: 980px) {
  .cases-description {
    &__container {
      display: flex;
      align-items: baseline;
      max-width: calc(100% - 244px)
    }

    &__back {
      margin-right: 40px;
    }

    &__img-back {
      width: 18px;
      height: 16px;
    }

    &__back-title {
      display: none;
    }

    &__title {
      font-size: 32px;
    }

    &__tag {
      font-size: 13px;
    }

    &__text-container {
      flex-direction: column;
      margin-bottom: 48px;
      padding-bottom: 48px;
    }

    &__text {
      max-width: 100%;
      margin-bottom: 48px;
      font-size: 13px;
    }


    &__text-about {
      flex-direction: initial;
    }

    &__text-count p {
      display: flex;

      &:first-child {
        margin-right: 40px;
      }
    }

    &__text-count span {
      padding-left: 10px;
    }

    &__subtitle {
      font-size: 32px;
    }
  }
}

@media only screen and (max-width: 710px) {
  .cases-description {
    &__back {
      display: none;
    }

    &__container {
      max-width: calc(100% - 114px);
      padding-left: 20px;
    }

    &__title-container {
      flex-wrap: wrap;
    }
  }
}
</style>
