<template>
  <div class="cases"
  >
    <Aside
        @change-theme="changeTheme"
    />
    <div class="cases__container">
      <div class="cases__title-container">
        <Title/>
      </div>
      <div class="cases__btns">
        <BtnList :activeBtn="activeBtn"
                 @setActiveBtn="setActiveBtn"

        />
      </div>
      <div class="cases__filter-list">
        <FilterList :activeBtn="activeBtn"
                    :serviceId="serviceId"
                    :categoryId="categoryId"
                    @setServiceId="setServiceId"
                    @setCategoryId="setCategoryId"
        />
      </div>
      <Case/>
      <Feedback/>
      <TitleNextPage/>
    </div>
  </div>
</template>
<script>
import Aside from '@/components/Aside.vue'
import Case from '@/components/Case.vue'
import FilterList from '@/components/FilterList.vue'
import Feedback from '@/components/Feedback.vue'
import TitleNextPage from '@/components/TitleNextPage.vue';
import BtnList from '@/components/BtnList.vue';
import Title from '@/components/Title.vue';

export default {
  name: 'cases',
  metaInfo() {
    if(this.langPhrases) {
      return {
        title: this.langPhrases.seo.cases.title,
        meta: [
          { name: 'description', content: this.langPhrases.seo.cases.description },
        ]
      }
    }
  },
  components: {
    Aside,
    Case,
    FilterList,
    Feedback,
    TitleNextPage,
    BtnList,
    Title,
  },
  data() {
    return {
      activeBtn: 'services',
      serviceId: 0,
      categoryId: 0,
    }
  },
  computed: {
    isItemHovered() {
      return this.$store.getters.IS_ITEM_HOVERED;
    },
    isItemsHover() {
      return this.$store.getters.IS_ITEMS_HOVER;
    },
    isItemIndexHovered() {
      return this.$store.getters.IS_ITEM_INDEX_HOVERED;
    },
    theme() {
      return this.$store.getters.THEME;
    },
    activeItemAside() {
      return this.$store.getters.ACTIVE_NAV_LINK;
    },
    list() {
      return this.$store.getters.LIST;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },
    activeLang() {
      return this.$store.getters.ACTIVE_LANG;
    },


  },
  watch: {
    activeLang() {
      this.$store.dispatch('fetchPortfolio');
    }
  },
  mounted() {
    this.$store.dispatch('clickNavLink', 0)

  },
  methods: {
    changeTheme(theme) {
      if (theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeBlack)
        document.body.style.setProperty('--background', this.backgroundThemeBlack)
      } else if (!theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeWhite)
        document.body.style.setProperty('--background', this.backgroundThemeWhite)
      }
    },
    onHover() {
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.add('cursor_hover');
    },
    offHover() {
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.remove('cursor_hover');
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    clickNavLink(index) {
      this.$store.dispatch('mouseOut', null)
      this.$store.dispatch('clickNavLink', index)
    },
    fetchPortfolio() {
      this.$store.dispatch('fetchPortfolio', {serviceId: this.serviceId, categoryId: this.categoryId})
    },
    setServiceId(item) {
      this.serviceId = item;
      this.fetchPortfolio();
    },
    setCategoryId(item) {
      this.categoryId = item
      this.fetchPortfolio();
    },
    setActiveBtn(code) {
      this.activeBtn = code;
      this.serviceId = 0;
      this.categoryId = 0;
      if(this.serviceId == 0 || this.categoryId == 0  ) {
        this.fetchPortfolio();
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/styles/vars.scss';

.cases {
  display: flex;
  background-color: var(--background);
  @extend %padding-right;

  &__title-container {
    margin-bottom: 78px;
  }

  &__container {
    @extend %padding-right;
    width: 100%;
    position: relative;
  }

  &__btns {

    margin-bottom: 42px;
  }

  &__filter-list {
    @extend %padding-left;
  }

  &__next-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    @extend %padding-left;
    @extend %padding-right;
    padding-bottom: 52px;
    transition: all .6s ease;
    overflow: hidden;
    max-height: 280px;
    height: 100%;

    & a {
      position: relative;
      height: 100%;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

    }

    &:hover {
      .cases__next {
        opacity: 1;
        transition: all .6s ease;

        & span {
          opacity: 1;
        }
      }

      .cases__next_visible {
        top: -100%;
      }

      .cases__next_hidden {
        top: 0;
      }
    }
  }

  &__next {
    @extend %font;
    display: flex;
    align-items: baseline;
    font-size: 320px;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-text);
    transition: all .6s ease;
    width: 100%;
    position: absolute;
    opacity: .05;

    & p {
      margin: 0;
      padding-top: 12px;
    }

    & span {
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      opacity: .3;
      margin-right: 0;
      margin-left: auto;
    }

  }

  &__next_visible {
    top: 0;
    left: 0;
    transition: all .6s ease;
  }

  &__next_hidden {
    top: 100%;
    left: 0;

  }

}

@media only screen and(max-width: 980px) {
  .cases {
    &__btns {
      margin-bottom: 0;
    }

    &__filter-list {

    }
  }
}

@media only screen and(max-width: 490px) {
  .cases {

    &__filter-list {
      //width: 100%;
    }
  }
}
</style>
