<template>
  <div class="aside">
    <div class="logo logo_theme_black"
         v-if="this.theme ">
      <router-link to="/">
        <img src="../assets/images/logo_white.svg" alt="логотип" class="logo__img">
        <img src="../assets/images/logo_title_white.svg" alt="логотип" class="logo__title"
             v-if="width >=710">
      </router-link>
    </div>
    <div class="logo "
         v-else-if="!this.theme">
      <router-link to="/">
        <img src="../assets/images/logo_black.svg" alt="логотип" class="logo__img">
        <img src="../assets/images/logo_title_black.svg" alt="логотип" class="logo__title">
      </router-link>
    </div>
    <div class="aside__step-back"
    >
      <CaseDescriptionBack v-if="snowCaseDescription"/>
      <div class="aside__step-back-wrap"
           @click="$router.go(-1)"
           v-else
      >
        <img class="aside__img-back" src="../assets/images/arrow_back_white.svg" alt="назад"
             v-if="this.theme">
        <img class="aside__img-back" src="../assets/images/arrow_back_black.svg" alt="назад"
             v-else-if="!this.theme">
      </div>
    </div>
    <div class="aside__options">
      <nav class="nav-list">
        <ul class="list">
          <li class="list__item-container"
              v-for="(item, index) in list"
              :key="index+1 "
              :class="{ 'active': index === activeItemAside }">
            <router-link :to="{path:item.path}"
                         @click.native="clickNavLink(index)">
              <div class="list__item">
                <p class="list__item-title list__item-title_visible" v-if="langPhrases">{{ langPhrases.menu[item.code] }}</p>
                <p class="list__item-title list__item-title_hide" v-if="langPhrases">{{ langPhrases.menu[item.code] }}</p>
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="aside__option">
        <div class="aside__theme"
             v-on:click="changeTheme ">
          <div class="aside__theme-pic"><span></span></div>
          <p class="aside__theme-title">{{ themeTitle }}</p>
        </div>
        <Lang/>
      </div>
    </div>
  </div>
</template>

<script>
import CaseDescriptionBack from '@/components/CaseDescriptionBack.vue';
import Lang from '@/components/Lang.vue';

export default {
  name: 'AsideMain',
  components: {
    Lang,
    CaseDescriptionBack,
  },
  data() {
    return {
      width: 0,
    }
  },
  computed: {
    activeItemAside() {
      return this.$store.getters.ACTIVE_NAV_LINK;
    },
    theme() {
      return this.$store.getters.THEME;
    },
    themeTitle() {
      return this.$store.getters.THEME_TITLE;
    },
    list() {
      return this.$store.getters.LIST;
    },
    snowCaseDescription() {
      return this.$store.getters.SHOW_DESCRIPTION;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES;
    },
  },
  watch: {},
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.width = window.innerWidth;
    this.onResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    changeTheme() {
      if (this.theme) {
        this.$store.dispatch('changeTheme', false)
        this.$store.dispatch('changeThemeTitle', 'White')
      } else if (!this.theme) {
        this.$store.dispatch('changeTheme', true)
        this.$store.dispatch('changeThemeTitle', 'Black')
      }
    },
    onResize() {
      this.width = window.innerWidth;
    },
    clickNavLink(index) {
      this.$store.dispatch('clickNavLink', index)
    },
    changeLang(lang) {
      this.$store.dispatch('changeLang', lang)
    }
  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';


a {
  text-decoration: none;
}

.active {
  .list__item {
    opacity: 1;
  }
}

.nav-list {
  margin-bottom: 58px;
}

.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  padding-right: 16px;
  height: 100%;

  &__item {
    @extend %font;
    text-transform: uppercase;
    font-size: 60px;
    color: var(--color-text);
    opacity: .05;
    position: relative;
    overflow: hidden;
    min-height: 58px;
    transition: all .4s linear;
    margin-bottom: 12px;


  }

  &__item-container a {

  }
  &__item-title_visible{
    top: 0;
  }

  &__item-title_hide {
    top: 100%;
    transition: all .4s linear;
  }

  &__item-title {
    margin: 0;
    position: absolute;
    overflow: hidden;
    min-height: 62px;
    transition: all .4s linear;
    padding-top: 4px;

  }
}


.aside {
  padding-top: 52px;
  padding-left: 60px;
  padding-bottom: 52px;
  max-width: calc(426px - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 104px);
  justify-content: space-between;
  position: sticky;
  top: 0;
  border-right: 1px solid #393B3E;

  &__option {
    display: flex;
    align-items: center;
  }

  &__theme {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 32px;
  }

  &__theme-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--color-text);
    overflow: hidden;
    margin-right: 16px;

    & span {
      display: inline-block;
      width: 50%;
      height: 100%;
      background-color: var(--color-text);
    }
  }

  &__theme-title {
    @extend %fontInter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text);
  }

  &__step-back {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: auto;
    border-top: 1px solid #393B3E;
    border-bottom: 1px solid #393B3E;
  }

  &__img-back {
    width: 14px;
    height: 12px;
  }
}

.logo {
  align-self: baseline;
  //padding-left: 48px;

  & a {
    display: flex;
  }

  &__img {
    width: 28px;
    height: 32px;
    object-fit: contain;
    margin-right: 8px;
  }

  &__title {
    width: 50px;
    height: 28px;
  }

  //margin-bottom: 400px;
}

@media only screen and (max-width: 1150px) {
  .logo {
    padding-left: 0;
    justify-content: center;

    &__img {
      width: 100%;
      height: 26px;
    }

    &__title {
      width: 100%;
      height: 22px;
    }
  }
  .aside {
    padding-left: 20px;
    max-width: 162px;

    &__theme-title {
      display: none;
    }

    &__theme {
      margin-right: 0;
    }

  }
  .list {
    &__item {
      font-size: 32px;
      min-height: 32px;
    }
  }
}

@media only screen and(min-width: 980px){
  .list{
    &__item{
      &:hover {
        opacity: 1;
        transition: all .4s linear;
        .list__item-title_visible{
          top: -100%;

        }
        .list__item-title_hide{
          top: 0;

        }
      }
    }

  }
}

@media screen and (min-width: 710px) {
  .aside {
    &__step-back {
      display: none;
    }
  }
}

@media only screen and (max-width: 710px) {
  .logo {
    align-self: center;

    &__title {
      display: none;
    }
  }
  .aside {
    max-width: 72px;
    padding-top: 40px;
    padding-left: 0;

    &__option {
      flex-direction: column-reverse;
    }

    &__theme-pic {
      margin-right: 0;
    }

  }
  .nav-list {
    display: none;
  }
}

</style>
