<template>
  <div class="title" v-if="langPhrases"><span>{{ langPhrases.menu[list[activeItemAside ].code] }}</span></div>
</template>

<script>


export default {
  name: 'TitleNextPage',
  components: {},
  data() {
    return {}
  },
  computed: {
    list() {
      return this.$store.getters.LIST;
    },
    activeItemAside() {
      return this.$store.getters.ACTIVE_NAV_LINK;
    },
    langPhrases(){
      return this.$store.getters.LANG_PHRASES;
    },

  },
  watch: {},
  mounted() {
  },

  methods: {},

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.title {
  @extend %padding-left;
  //padding-left: $padding-left;
  border-left: 1px solid #FFFFFF;
  display: flex;

  & span {
    @extend %font;
    display: inline-block;
    margin: 0;
    font-size: 320px;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-text);
    //transform: translateY(68px);
    transform: translateY(22%);

  }
}

@media only screen and (max-width: 1800px ){
  .title{
    & span{
      font-size: 14em;
    }
  }
}
@media only screen and (max-width: 1180px) {
  .title {
    border-left: none;

    & span {
      font-size: 120px;
      transform: translateY(38px);
    }
  }
}

@media only screen and (max-width: 720px) {
  .title {
    & span {
      font-size: 64px;
      transform: translateY(28px);
    }
  }
}

</style>
