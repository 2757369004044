<template>
  <div class="filter-list">
    <div class="filter-list__title"
         v-if="width < 980 "
         @click="showList">
      <p>{{ checkedItem }}</p>
      <img class="filter-list__img" src="../assets/images/icon_arrow_down.svg" alt="стрелка">
    </div>
    <ul class="filter-list__list"
        :class="{'filter-list__list_show': listShow}"
    >
      <li
          v-for="(item, index) in filterList"
          :key="item.id "
          :class=" {'filter-list__item_active': item.id == activeListItem} "
          @click=" filterClick(item, index);"
          class="filter-list__item"
      >{{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script>


export default {
  name: 'ClientsCategories',
  components: {},
  props: {},
  data() {
    return {
      activeListItem: 1,
      width: 0,
      listShow: false,
      checkedItem: null,
    }
  },
  computed: {
    clientsCategories() {
      return this.$store.getters.CLIENTS_CATEGORIES;
    },
    activeLang() {
      return this.$store.getters.ACTIVE_LANG;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },
    filterList() {
      if (Array.isArray(this.clientsCategories)) {
        return [{id: 0, title: this.langPhrases.page.clients.filter.all}, ...this.clientsCategories];
      }
      return [];
    },

  },
  watch: {
    activeLang() {
      this.$store.dispatch('fetchClientsCategories');
    },
    langPhrases() {
      this.checkedItem = this.langPhrases.page.clients.filter.all;
    }
  },
  beforeCreate() {
  },
  created() {
    this.$store.dispatch('fetchClientsCategories');

  },
  beforeMount() {
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkedItem = this.langPhrases.page.clients.filter.all;
  },
  beforeUpdate() {
  },
  updated() {
  },
  activated() {
  },
  deactivated() {
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  destroyed() {
  },
  methods: {
    filterClick(item) {
      this.activeListItem = item.id;
      this.checkedItem = item.title;
      this.showList();
      this.$emit('filterClick', this.activeListItem)
      // this.filterList.unshift(...this.filterList.splice(index, 1));

    },
    onResize() {
      this.width = window.innerWidth;
    },
    showList() {
      this.listShow = !this.listShow;
    },
  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';


.filter-list {
  padding: 0;
  margin-bottom: 78px;

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    @extend %fontInter;
    font-size: 16px;
    color: var(--color-text);
    opacity: 0.3;
    margin-bottom: 10px;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  &__item_active {
    opacity: 1;
  }

  &__title {
    border-bottom: 1px solid #393B3E;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    & p {
      @extend %fontInter;
      font-size: 16px;
      color: var(--color-text);
    }

  }

  &__img {

  }


}

@media only screen and (max-width: 980px) {
  .filter-list {
    width: 50%;
    position: relative;

    &__title p {
      font-size: 13px;
    }

    &__item {
      font-size: 13px;
    }

    &__list {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: all .5s ease;

    }

    &__list_show {
      background-color: var(--background);
      opacity: 1;
      visibility: visible;
      transition: all .5s ease;
    }
  }
}

@media only screen and(max-width: 490px) {
  .filter-list {
    width: 100%;
  }
}
</style>
