<template>
  <div class="feedback">
    <div class="feedback__location "
         v-if="activeBtn == 'moscow'">
      <div class="feedback__wrap">
        <div class="feedback__container-wrap">
          <span class="feedback__title" v-if="langPhrases">{{ langPhrases.page.portfolio.phone }}</span>
          <div class="feedback__container">
            <div class="feedback__contact-wrap" v-if="langPhrases">
              <a :href="`tel:${langPhrases.page.contacts.moscow.phone}`"
                 class="feedback__contact feedback__contact_phone">{{
                  langPhrases.page.contacts.moscow.phone
                }}</a>
              <a :href="`tel:${langPhrases.page.contacts.moscow.phone}`"
                 class="feedback__contact feedback__contact_hidden feedback__contact_phone">{{
                  langPhrases.page.contacts.moscow.phone
                }}</a>
            </div>
          </div>
        </div>
        <div class="feedback__container-wrap">
          <span class="feedback__title" v-if="langPhrases">{{ langPhrases.page.portfolio.email }}</span>
          <div class="feedback__container">
            <div class="feedback__contact-wrap">
              <div class="feedback__contact-wrap" v-if="langPhrases">
                <a :href="`mailto:${langPhrases.page.contacts.moscow.email}`"
                   class="feedback__contact feedback__contact_mail">{{ langPhrases.page.contacts.moscow.email }}</a>
                <a :href="`mailto:${langPhrases.page.contacts.moscow.email}`"
                   class="feedback__contact feedback__contact_hidden feedback__contact_mail">{{
                    langPhrases.page.contacts.moscow.email
                  }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="feedback__container-wrap">
        <span class="feedback__title" v-if="langPhrases">{{ langPhrases.page.contacts.feedback }}</span>
        <div class="feedback__container ">
          <div class="feedback__contact-wrap" v-if="langPhrases">
            <a :href="`mailto:${langPhrases.page.contacts.moscow.email}`"
               class="feedback__contact feedback__contact_btn">{{ langPhrases.page.contacts.feedbackBtn }}</a>
            <a :href="`mailto:${langPhrases.page.contacts.moscow.email}`"
               class="feedback__contact feedback__contact_hidden feedback__contact_btn">{{
                langPhrases.page.contacts.feedbackBtn
              }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="feedback__location "
         v-else-if="activeBtn == 'london'">
      <div class="feedback__wrap">
        <div class="feedback__container-wrap">
          <span class="feedback__title" v-if="langPhrases">{{ langPhrases.page.portfolio.phone }}</span>
          <div class="feedback__container">
            <div class="feedback__contact-wrap" v-if="langPhrases">
              <a :href="`tel:${langPhrases.page.contacts.london.phone}`"
                 class="feedback__contact feedback__contact_phone">{{
                  langPhrases.page.contacts.london.phone
                }} </a>
              <a :href="`tel:${langPhrases.page.contacts.london.phone}`"
                 class="feedback__contact feedback__contact_hidden feedback__contact_phone">{{
                  langPhrases.page.contacts.london.phone
                }}</a>
            </div>
          </div>
        </div>
        <div class="feedback__container-wrap">
          <span class="feedback__title" v-if="langPhrases">{{ langPhrases.page.portfolio.email }}</span>
          <div class="feedback__container">
            <div class="feedback__contact-wrap" v-if="langPhrases">
              <a :href="`mailto:${langPhrases.page.contacts.london.email}`"
                 class="feedback__contact feedback__contact_mail">{{ langPhrases.page.contacts.london.email }}</a>
              <a :href="`mailto:${langPhrases.page.contacts.london.email}`"
                 class="feedback__contact feedback__contact_hidden feedback__contact_mail">{{
                  langPhrases.page.contacts.london.email
                }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="feedback__container-wrap">
        <span class="feedback__title" v-if="langPhrases">{{ langPhrases.page.contacts.feedback }}</span>
        <div class="feedback__container ">
          <div class="feedback__contact-wrap" v-if="langPhrases">
            <a :href="`mailto:${langPhrases.page.contacts.london.email}`"
               class="feedback__contact feedback__contact_btn">{{ langPhrases.page.contacts.feedbackBtn }}</a>
            <a :href="`mailto:${langPhrases.page.contacts.london.email}`"
               class="feedback__contact feedback__contact_hidden feedback__contact_btn">{{
                langPhrases.page.contacts.feedbackBtn
              }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FeedbackUs',
  components: {},
  props: {
    activeBtn: String,
  },
  data() {
    return {}
  },
  mounted() {

  },
  computed: {
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },
  },
  methods: {},
}
</script>
<style scoped lang="scss">
@import '../assets/styles/vars';


.feedback {
  @extend %padding-left;

  &__location {
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
    //margin-bottom: 280px;
  }

  &__wrap {
    display: flex;
    max-width: 64%;
    width: 100%;
    justify-content: space-between;
  }

  &__title {
    @extend %fontInter;
    display: inline-block;
    margin-bottom: 14px;
    color: var(--color-text);
    opacity: .3;
  }

  &__container {
    max-width: 302px;
    width: 100%;
    height: 120px;
    margin-right: 76px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }

  &__container-wrap {
    max-width: 302px;
    width: 100%;
    height: 120px;

  }
  &__contact-wrap {
    display: flex;
    max-height: 56px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: relative;

  }
  &__contact {
    @extend %font;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 32px;
    color: var(--color-text);
    width: 100%;
    overflow: hidden;
    min-height: 62px;
    transition: all .4s linear;
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  &__contact_phone {
    position: relative;


    &::after {
      content: '+7(495)150 70 95';
      position: absolute;
      top: 150%;
      left: 0;
      transition: all .4s linear;

    }
  }

  &__contact_mail {
    position: relative;
  }

  &__contact_btn {
    position: relative;

    & span {
      display: inline-block;
      left: 0;
    }

  }

  &__contact_hidden {
    position: absolute;
    top: 150%;
    transition: all .4s linear;

  }
}

@media only screen and (max-width: 1600px) {
  .feedback {
    &__wrap {
      flex-wrap: wrap;
    }

    &__container {
      height: 70px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .feedback {
    &__container {
      height: 84px;
    }

    &__title {
      margin-bottom: 0;
    }

    &__contact {
      font-size: 44px;
      justify-content: flex-start;
    }
  }
}

@media only screen and (min-width: 980px) {
  .feedback {
    &__container {
      &:hover {
        .feedback__contact {
          top: -100%;
        }

        .feedback__contact_hidden {
          top: 54%;
        }

      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .feedback {

    padding-left: 48px;

    &__location {
      margin-bottom: 0;
      display: flex;
      flex-direction: initial;
    }

    &__contact {
      margin-bottom: 0;
    }

    &__item-title_hidden {
      display: none;
    }
  }
}

@media only screen and (max-width: 720px) {
  .feedback {

    &__location {
      flex-direction: column;
    }
  }
}

@media only screen and(max-width: 490px) {
  .feedback {
    padding-left: 20px;

    &__container-wrap {
      height: 86px;
    }

    &__container {
      height: 50px;
    }

    &__contact {
      font-size: 24px;
    }
  }
}
</style>
