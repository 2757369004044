<template>
  <div class="cases-description-back"
       @click="hideDescription"
  >
    <img class="cases-description-back__img" src="../assets/images/arrow_back_white.svg" alt="назад"
         v-if="this.theme">
    <img class="cases-description-back__img" src="../assets/images/arrow_back_black.svg" alt="назад"
         v-else-if="!this.theme">
    <div class="cases-description-back__title">{{langPhrases.page.portfolio.detail.close}}</div>
  </div>
</template>

<script>


export default {


  name: 'CaseDescriptionBack',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    theme() {
      return this.$store.getters.THEME;
    },
    langPhrases(){
      return this.$store.getters.LANG_PHRASES;
    },
  },
  watch: {},
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  activated() {
  },
  deactivated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  methods: {
    hideDescription() {
      this.$store.dispatch('showCaseDescription', false)
    },
  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';


.cases-description-back {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  cursor: default;

  &__img {
    margin-right: 20px;
    width: 24px;
    height: 22px;
  }

  &__title {
    @extend %font;
    font-size: 32px;
    color: var(--color-text);
    opacity: .3;
    text-transform: uppercase;
  }

}


@media only screen and(max-width: 980px) {
  .cases-description-back {
    margin-right: 40px;

    &__img {
      width: 18px;
      height: 16px;
    }

    &__title {
      display: none;
    }
  }
}

@media only screen and(max-width: 710px) {
  .cases-description-back {
    display: block;
    margin-right: 0;
    margin-bottom: 0;

    &__img {
      width: 14px;
      height: 12px;
      margin-right: 0;
    }
  }
}

</style>
