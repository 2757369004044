<template>
  <div class="cases__items">
    <CaseDescription
        v-if="caseDescription"
        :class="{'cases-description_show': this.snowCaseDescription }"
    />
    <div class="cases__item"
         v-for="item in this.сases"
         :key="item.id">
      <div class="cases__list-title"
           v-show="item.displayTitle">{{ item.title }}
      </div>
      <div class="case"
           v-for="item in item.items"
           :key="item.id"
           :class="{'case_without_img': item.previewPicture == null, 'case__img-container_xl': item.size === 'xl',  }">

        <div v-if="item.previewPicture" class="case__img-container"
             @click="showDescription(item.id)"

        >
          <img :src="item.previewPicture.src" :alt="item.title" class="case__img"
               @mouseover="hoverCase"
               @mouseout="offHover">
        </div>
        <div class="case__description">
          <h2 class="case__title"
              @click="showDescription(item.id)"
              @mouseover="hoverCase"
              @mouseout="offHover">
            {{ item.title }}
          </h2>
          <ul class="case__tags"
              :class="{'case__tags_img': (item.image != null) || (item.image != undefined) }">
            <li class="case__tag"
                v-for="item in item.tags"
                :key="item.id"
            >
              {{ item }}
            </li>
          </ul>
          <a :href="item.link" class="case__link" target="_blank"
             v-if="item.link">Сайт</a>
        </div>
      </div>
    </div>
    <PagePreLoader v-show="preloader > 0"/>

  </div>
</template>

<script>
import CaseDescription from '@/components/CaseDescription.vue'
import PagePreLoader from "@/components/PagePreLoader";

export default {
  name: 'Case',
  components: {
    PagePreLoader,
    CaseDescription,
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    theme() {
      return this.$store.getters.THEME;
    },
    snowCaseDescription() {
      return this.$store.getters.SHOW_DESCRIPTION;
    },
    сases() {
      return this.$store.getters.CASES;
    },
    caseDescription() {
      return this.$store.getters.CASE_DESCRIPTION;
    },
    preloader() {
      return this.$store.getters.PRELOADER;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },
    caseId() {
      return this.$store.getters.CASE_ID;
    },
    activeLang() {
      return this.$store.getters.ACTIVE_LANG;
    },

  },
  watch: {
    activeLang() {
      this.$store.dispatch('fetchPortfolio');
      if (this.caseId) {
        this.$store.dispatch('fetchPortfolioDescription', this.caseId);
      }
    },

  },
  beforeCreate() {
  },
  created() {
    this.$store.dispatch('fetchPortfolio');
  },
  beforeMount() {
  },
  mounted() {
    this.mouseHover();
  },
  beforeUpdate() {
  },
  updated() {
  },
  activated() {
  },
  deactivated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  methods: {
    mouseHover(isItemHovered) {
      if (!isItemHovered) {
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover')
        document.querySelector('.cursor').classList.remove('cursor_hover')
      } else if (isItemHovered) {
        document.querySelector('.cursor-dot').classList.add('cursor-dot_hover')
        document.querySelector('.cursor').classList.add('cursor_hover')

      }
    },
    showDescription(descriptionId) {
      this.$store.commit('SET_CASE_ID', descriptionId)
      this.$store.dispatch('fetchPortfolioDescription', descriptionId);
      setTimeout(() => {
        this.$store.dispatch('showCaseDescription', true)
      }, 400)
    },
    cases() {
      return this.$store.getters.CASES;
    },
    hoverCase() {
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover_blog');
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover');
      document.querySelector('.cursor').classList.add('cursor_hover');
    },
    offHover() {
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_slide_prev');
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_blog');
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_slide_prev');
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover');
      document.querySelector('.cursor').classList.remove('cursor_hover');
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.cases {
  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
    border-bottom: 1px solid #393B3E;
    width: 100%;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    @extend %padding-left;
  }

  &__list-title {
    @extend %font;
    color: var(--color-text);
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 60px;
    width: 100%;
  }
}

.case {
  margin-bottom: 60px;
  width: calc(50% - 14px);
  display: flex;
  flex-direction: column;

  //&:nth-child(2n) {
  //  margin-right: 28px;
  //}

  &_without_img {
    width: 100%;
    //border-top: 1px solid #393B3E;
    border-bottom: 1px solid #393B3E;
    margin-bottom: 0;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(2n) {
      margin-right: 0;
      width: 100%;
    }


  }

  & ~ .case_without_img {
    border-top: 1px solid #393B3E;
  }

  &__img-container {
    //background: #FFFFFF;
    //max-width: 640px;
    //padding-top: 60px;
    width: 100%;
    max-height: 27em;
    height: 100%;
    display: flex;
    margin-bottom: 28px;
    overflow: hidden;

  }

  &__img-container_xl {
    width: 100%;

    .case__img-container {
      max-width: 1332px;
      width: 100%;
    }

  }

  &__img {
    width: 100%;
    object-fit: cover;
  }

  &__description {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
  }

  &__title {
    @extend %font;
    color: var(--color-text);
    font-size: 60px;
    font-weight: 700;
    margin-right: 40px;
    text-transform: uppercase;
    max-width: 87%;

  }

  &__tags {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-right: 20px;
  }

  &__tags_img {

  }

  &__tag {
    @extend %fontInter;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text);
    margin-right: 16px;
    cursor: default;
    opacity: .5;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    margin-right: 0;
    margin-left: auto;
    @extend %fontInter;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text);
    text-decoration: underline;

    &:hover {
      opacity: .3;
    }
  }
}

@media only screen and(max-width: 1700px) {
  .case {
    &__tags_img {
      order: 2;
      width: 100%;
      flex-direction: column;
    }

    &__tag {
      margin-bottom: 8px;
    }

  }
}

@media only screen and(max-width: 1200px) {
  .case {
    &__tags {
      margin-right: 0;
    }
  }
}

@media only screen and(max-width: 980px) {
  .case {
    &__title {
      font-size: 32px;
      margin-right: 20px;
    }

    &__tags {
      padding-top: 20px;
    }

    &__tag {
      font-size: 13px;
    }

    &__link {
      font-size: 13px;
    }

    &_without_img {
      height: 100px;
    }

    &__tags {
      order: 2;
      width: 100%;
    }

  }
  .cases {
    &__item {
      margin-bottom: 50px;
    }

    &__list-title {
      margin-bottom: 50px;
    }
  }
}

@media only screen and(min-width: 980px) {
  .case {
    &__img-container:hover {


    }
  }
}

@media only screen and(max-width: 680px) {
  .case {
    width: 100%;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &__img-container {
      max-width: 1332px;
      width: 100%;
      height: auto;
    }

    &_without_img {
      height: auto;
      padding-top: 36px;
      padding-bottom: 36px;
    }

    &__title {
      margin-right: 0;
    }

    &__tags {
      flex-direction: column;
    }
  }
}
</style>

