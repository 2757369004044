<template>
  <div class="clients">
    <Aside
        @change-theme="changeTheme"/>
    <div class="clients__container">
      <div class="clients__title-container">
        <Title/>
      </div>
      <div class="clients__wrap">
        <div class="clients__filters">
          <div class="clients__counter">
            <span class="clients__counter_active">{{ countActive }}</span>
            <span class="clients__counter-separator">/</span>
            <span class="clients__counter_all"> {{ clientsList.length }}</span>
          </div>
          <ClientsCategories
              @filterClick="filterClick"/>
        </div>
        <div class="clients__items">
          <div class="clients__item"
               :class="{'clients__item_active': item.sectionId == search  }"
               v-for="item in clientsList"
               :key="item.id"
          >
            <div class="clients__item-border"></div>
            <img class="clients__item-img"
                 v-if="item.logo"
                 :class="{'clients__item-img_white': !theme   }"
                 :src="item.logo.src" :alt="item.logo">
            <div class="clients__item-title"
                 v-if="!item.logo">{{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <TitleNextPage/>
    </div>
  </div>
</template>
<script>
import Aside from '@/components/Aside.vue';
import TitleNextPage from '@/components/TitleNextPage.vue';
import ClientsCategories from '@/components/ClientsCategories.vue';
import Title from '@/components/Title.vue';

export default {
  name: 'Clients',
  metaInfo() {
    if(this.langPhrases) {
      return {
        title: this.langPhrases.seo.clients.title,
        meta: [
          { name: 'description', content: this.langPhrases.seo.clients.description },
        ]
      }
    }
  },
  components: {
    Aside,
    TitleNextPage,
    ClientsCategories,
    // Blog,
    Title,
  },
  data() {
    return {
      search: 0,
    }
  },
  computed: {
    isItemHovered() {
      return this.$store.getters.IS_ITEM_HOVERED;
    },
    isItemsHover() {
      return this.$store.getters.IS_ITEMS_HOVER;
    },
    isItemIndexHovered() {
      return this.$store.getters.IS_ITEM_INDEX_HOVERED;
    },
    theme() {
      return this.$store.getters.THEME;
    },
    activeItemAside() {
      return this.$store.getters.ACTIVE_NAV_LINK;
    },
    list() {
      return this.$store.getters.LIST;
    },
    activeLang() {
      return this.$store.getters.ACTIVE_LANG;
    },
    clientsList() {
      return this.$store.getters.CLIENTS_LIST;
    },
    countActive() {
      return this.clientsList.filter((el) => {
        return el.sectionId === this.search
      }).length
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    }
  },
  watch: {
    activeLang() {
      this.$store.dispatch('fetchClientsList');
    }
  },
  created() {
    this.$store.dispatch('fetchClientsList')
  },
  mounted() {
    this.scrollTop();
    this.$store.dispatch('clickNavLink', 3);
    this.mouseHover();
  },
  methods: {
    changeTheme(theme) {
      if (theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeBlack)
        document.body.style.setProperty('--background', this.backgroundThemeBlack)
      } else if (!theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeWhite)
        document.body.style.setProperty('--background', this.backgroundThemeWhite)
      }
    },
    onHover() {
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.add('cursor_hover');

    },
    offHover() {
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.remove('cursor_hover');
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    mouseHover() {
      if (!this.isItemHovered) {
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover');
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
        document.querySelector('.cursor').classList.remove('cursor_hover')
      } else if (this.isItemHovered) {
        document.querySelector('.cursor-dot').classList.add('cursor-dot_hover')
        document.querySelector('.cursor').classList.add('cursor_hover')

      }
    },
    handleScroll() {
      if (window.scrollY > 0) {
        this.scroll = true;
      }
    },
    clickNavLink(index) {
      this.$store.dispatch('mouseOut', null)
      this.$store.dispatch('clickNavLink', index)
    },
    filterClick(item) {
      this.search = item
    },
  }
  ,

}
</script>
<style lang="scss" scoped>
@import '../assets/styles/vars.scss';

.clients {

  display: flex;
  background-color: var(--background);
  //overflow: hidden;

  &__container {
    @extend %padding-right;
    display: flex;
    flex-direction: column;
    width: 100%;
    @extend %max-width;
    overflow: hidden;

  }

  &__title-container {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 108px;
  }

  &__title {
    @extend %padding-left;
    border-left: 1px solid #FFFFFF;
    display: flex;
    margin-bottom: 78px;

    & span {
      @extend %font;
      display: inline-block;
      margin: 0;
      font-size: 320px;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--color-text);
      transform: translateY(68px);

    }
  }

  &__filters {
    margin-right: 50px;

  }

  &__title {
    @extend %padding-left;
    border-left: 1px solid #FFFFFF;
    display: flex;

    & span {
      @extend %font;
      display: inline-block;
      margin: 0;
      font-size: 320px;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--color-text);
      transform: translateY(68px);

    }
  }

  &__items {
    display: grid;
    //max-width: 1120px;
    //max-width: calc(100% - 400px);
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(270px, 366px));
    margin-bottom: 186px;
    //grid-gap: 4px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--background);
    height: 160px;

    &:hover {
      background-color: var(--color-text);

      .clients__item-title {
        filter: invert(100%);
      }
      .clients__item-img{
        filter: invert(100%);
      }
    }
  }

  &__item-border {
    position: absolute;
    top: -1px;
    left: -1px;
    right: 0;
    bottom: 0;
    border: 1px solid #393B3E;
    opacity: .3;
    background: transparent;
  }

  &__item_active {
    filter: invert(100%);
    opacity: 1;
  }

  &__item-img {
    opacity: 1;
  }

  &__item-title {
    @extend %fontInter;
    color: var(--color-text);
    font-size: 32px;
  }

  &__item-img_white {
    filter: invert(100%);
  }

  &__wrap {
    display: flex;
    @extend %padding-left;
    //@extend %padding-right;
  }

  &__counter {
    display: flex;
    align-items: baseline;
    min-width: 156px;
    margin-bottom: 38px;
    @extend %font;
    color: var(--color-text);
    font-size: 32px;
    font-weight: 700;
  }

  &__counter_active {
    font-size: 112px;
    margin-right: 40px;
  }

  &__counter_all {
    font-size: 32px;
  }

  &__counter-separator {
    display: inline-block;
    margin-right: 6px;
  }

  &__blog {
    @extend %padding-left;
    margin-bottom: 184px;
  }

}

@media only screen and (max-width: 1560px) {
  .clients {
    &__items {
      justify-content: center;
    }

    &__item {
      height: 120px;
    }

    &__wrap {
      flex-direction: column;
    }

    &__filters {
      display: flex;
      margin-right: 0;
    }

    &__counter {
      order: 1;
      margin-right: 0;
      margin-left: auto;
      justify-content: end;
    }
  }
}

@media only screen and(max-width: 1200px) {
  .clients {


    &__counter {
      font-size: 16px;
    }

    &__counter_active {
      font-size: 64px;
    }
  }
}

@media only screen and(max-width: 1080px) {
  .clients {
    &__counter {
      transform: translateY(-48%);
    }
  }
}

@media only screen and(max-width: 980px) {
  .filter-list {
    width: 304px;
  }
  .clients {
    &__counter {
      transform: translateY(-54%);
    }

    &__title-container {
      margin-bottom: 24px;
    }

    &__items {
      margin-bottom: 120px;
    }
  }
}

@media only screen and(max-width: 720px) {
  .filter-list {
    width: 254px;
  }
  .clients {
    &__counter {
      display: none;
    }

    &__item {
      height: 102px;
    }

    &__items {
      margin-bottom: 64px;
    }
  }
}
</style>
