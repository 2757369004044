<template>
  <div class="not-found">
    <Aside @change-theme="changeTheme"/>
    <div class="not-found__container">
      <div class="not-found__title-container">
        <div class="not-found__title"> {{ langPhrases.page.page404.title }}</div>
        <div class="not-found__subtitle">
          {{ langPhrases.page.page404.text }}
        </div>
      </div>
      <div class="not-found__img-container">
        <img src="../assets/images/elephant_m.png" alt="elephant" class="not-found__img">
      </div>
      <div class="not-found__btn-container">
        <router-link to="/">
          <div class="not-found__btn">{{ langPhrases.page.page404.btn }}</div>
          <div class="not-found__btn not-found__btn_hidden ">{{ langPhrases.page.page404.btn }}</div>
        </router-link>
      </div>

    </div>
  </div>
</template>
<script>
import Aside from '@/components/Aside.vue';


export default {
  name: '404',
  components: {
    Aside,
  },
  data() {
    return {}
  },
  computed: {
    theme() {
      return this.$store.getters.THEME;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },
  },
  watch: {},
  created() {
  },
  mounted() {

  },
  methods: {
    changeTheme(theme) {
      if (theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeBlack)
        document.body.style.setProperty('--background', this.backgroundThemeBlack)
      } else if (!theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeWhite)
        document.body.style.setProperty('--background', this.backgroundThemeWhite)
      }
    },
  }

}
</script>
<style lang="scss" scoped>
@import '../assets/styles/vars.scss';

.not-found {
  display: flex;

  background-color: var(--background);

  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 52px;
    @extend %max-width;
    @extend %padding-right;
    @extend %padding-left;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding-top: 118px;
    //align-items: center;
    //justify-content: center;

  }

  &__title {
    @extend %font;
    color: var(--color-text);
    font-size: 320px;
    margin-bottom: 100px;
  }

  &__subtitle {
    @extend %font;
    text-transform: uppercase;
    color: var(--color-text);
    font-size: 32px;
    max-width: 200px;
  }

  &__img-container {
    display: flex;
    max-width: 690px;
    width: 100%;
    max-height: 1000px;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 118px;
    right: 0;
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__btn-container {
    margin-bottom: 0;
    margin-top: auto;
    height: 120px;
    display: flex;
    width: 100%;
    //align-items: center;
    //justify-content: center;
    border: 1px solid var(--color-text);
    z-index: 2;

    position: relative;
    overflow: hidden;


  }

  &__btn {
    width: 100%;
    @extend %font;
    font-size: 32px;
    color: var(--color-text);
    transition: all .4s linear;

  }

  &__btn_hidden {
    display: none;
  }
}

@media only screen and(min-width: 950px) {
  .not-found {
    &__btn {
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translateY(-50%);
    }

    &__btn_hidden {
      display: initial;
      transition: all .4s linear;
      top: 150%;
    }

    &__btn-container {
      &:hover {
        transition: all .4s linear;

        .not-found__btn {
          top: -50%;
        }

        .not-found__btn_hidden {
          top: 52%;
        }
      }
    }
  }
}

@media only screen and(max-width: 950px) {
  .not-found {
    &__title-container {
      align-items: center;
    }

    &__title {
      font-size: 27vw;
    }

    &__subtitle {
      font-size: 6vw;
      max-width: 70%;
    }

    &__img-container {
      display: none;
    }

    &__btn-container {
      align-items: center;
      justify-content: center;
      height: 80px;
    }
  }
}
</style>
