<template>
    <div class="contact-us">
        <Aside
            @change-theme="changeTheme"/>
        <div class="contact-us__container">
            <div class="contact-us__title-container">
                <Title/>
            </div>
            <div class="contact-us__btns">
                <ContactBtn
                    :actiTabveBtn.sync="activeBtn"
                />
            </div>
            <div class="contact-us__about"
                 v-if="activeBtn == 'moscow'">
                <p v-if="langPhrases">{{ langPhrases.page.contacts.moscow.address }}</p>
            </div>
            <div class="contact-us__about"
                 v-else-if="activeBtn == 'london'">
                <p v-if="langPhrases">{{ langPhrases.page.contacts.london.address }}</p>
            </div>
            <div class="contact-us__contacts">
                <FeedbackUs
                    :activeBtn="activeBtn"/>
            </div>
        </div>

    </div>
</template>

<script>
import Aside from '@/components/Aside.vue';
import ContactBtn from '@/components/ContactBtn.vue';
import FeedbackUs from '@/components/FeedbackUs.vue';
import Title from '@/components/Title.vue';

export default {
    name: 'ContactUs',
  metaInfo() {
    if(this.langPhrases) {
      return {
        title: this.langPhrases.seo.contacts.title,
        meta: [
          { name: 'description', content: this.langPhrases.seo.contacts.description },
        ]
      }
    }
  },
    components: {
        Aside,
        ContactBtn,
        FeedbackUs,
        Title,
    },
    data() {
        return {
            activeBtn: 'moscow',
        }
    },
    computed: {
        theme() {
            return this.$store.getters.THEME;
        },
        langPhrases() {
            return this.$store.getters.LANG_PHRASES
        },
    },
    watch: {},
    created() {
    },
    mounted() {
        this.scrollTop();
        this.$store.dispatch('clickNavLink', 4);
        this.mouseHover();
    },
    methods: {
        changeTheme(theme) {
            if (theme) {
                document.body.style.setProperty('--color-text', this.colorTextThemeBlack)
                document.body.style.setProperty('--background', this.backgroundThemeBlack)
            } else if (!theme) {
                document.body.style.setProperty('--color-text', this.colorTextThemeWhite)
                document.body.style.setProperty('--background', this.backgroundThemeWhite)
            }
        },
        onHover() {
            document.querySelector('.cursor-dot').classList.add('cursor-dot_hover_page_next');
            document.querySelector('.cursor').classList.add('cursor_hover');
        },
        offHover() {
            document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
            document.querySelector('.cursor').classList.remove('cursor_hover');
        },
        scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        mouseHover() {
            if (!this.isItemHovered) {
                document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover');
                document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
                document.querySelector('.cursor').classList.remove('cursor_hover')
            } else if (this.isItemHovered) {
                document.querySelector('.cursor-dot').classList.add('cursor-dot_hover')
                document.querySelector('.cursor').classList.add('cursor_hover')
            }
        },
        handleScroll() {
            if (window.scrollY > 0) {
                this.scroll = true;
            }
        },
        clickNavLink(index) {
            this.$store.dispatch('mouseOut', null)
            this.$store.dispatch('clickNavLink', index)
        },
    },
}
</script>
<style lang="scss" scoped>
@import '../assets/styles/vars.scss';


.contact-us {
    display: flex;
    background-color: var(--background);
    @extend %padding-right;

    &__container {
        @extend %padding-right;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__title-container {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        margin-bottom: 108px;
    }

    &__title {
        @extend %padding-left;
        border-left: 1px solid #FFFFFF;
        display: flex;

        & span {
            @extend %font;
            display: inline-block;
            margin: 0;
            font-size: 320px;
            font-style: normal;
            font-weight: bold;
            text-transform: uppercase;
            color: var(--color-text);
            transform: translateY(68px);

        }
    }

    &__btns {
        margin-bottom: 84px;
    }

    &__about {
        @extend %padding-left;
        @extend %fontInter;
        font-size: 16px;
        font-weight: 400;
        color: var(--color-text);
        max-width: 100%;
        margin-bottom: 102px;

        & p {
            margin: 0;
        }
    }

    &__contacts {
        //padding-bottom: 142px;
        //border-bottom: 1px solid #393B3E;
        margin-bottom: 0;
        margin-top: auto;
    }

}

.feedback {
    margin: 0;
}


@media only screen and(max-width: 1150px) {
    .contact-us {
        &__contacts {
            padding-bottom: 52px;
        }


    }

}
</style>

