<template>

  <div class="technology">
    <div class="technology__item"
         v-for="(item, index) in technologies"
         :key="item.id"
         :class="{'technology__item_show': showSublist === item.id } "
         @click="sublistShow(item.id)">
      <div class="technology__container">
        <span class="technology__title-id">{{ index +1 }}</span>
        <div class="technology__title-container">
          <p class="technology__title">{{ item.title }}</p>
          <div class="technology__sublist">
            <p class="technology__subtitle"
               v-for="item in item.items"
               :key="item.id">{{ item.title }}</p>
          </div>
        </div>
        <img class="technology__title-img" src="../assets/images/icon_plus.svg" alt="лого">

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Technology',
  components: {},
  props: {},
  data() {
    return {
      width: 0,
      showSublist: null,

    }
  },
  computed: {
    technologies() {
      return this.$store.getters.TECHNOLOGIES
    },
    activeLang() {
      return this.$store.getters.ACTIVE_LANG;
    },
  },
  watch: {
    activeLang() {
      this.$store.dispatch('fetchTechnologies');
    }
  },
  beforeCreate() {
  },
  created() {
    this.$store.dispatch('fetchTechnologies')
  },
  beforeMount() {
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();

  },
  beforeUpdate() {
  },
  updated() {
  },
  activated() {
  },
  deactivated() {
  },
  beforeDestroy() {
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    sublistShow(id) {
      if (this.width < 980 && id !== this.showSublist) {
        this.showSublist = id;
      } else if (this.width < 980) {
        this.showSublist = null;
      }
    },
    onResize() {
      this.width = window.innerWidth;
    },

  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.technology {
  display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(272px, 446px));
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 8px;


  &__item {
    height: 160px;
    @extend %font;
    font-size: 32px;
    color: var(--color-text);
    width: 100%;
    font-weight: 700;
    align-items: center;
    text-transform: uppercase;
    position: relative;
    transition: all .6s ease;
    border: 1px solid #393B3E;
  }

  &__title-img {
    margin-right: 20px;
    margin-left: auto;
    transition: all .6s ease;
  }

  &__container {
    display: flex;
    align-items: baseline;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 1px solid transparent;
    background-color: var(--background);
    height: 120px;
    padding-top: 30px;
    overflow: hidden;
    z-index: 0;
    transition: .5s ease;

  }

  &__title-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all .6s ease;

  }

  &__title-id {
    opacity: .05;
    display: inline-block;
    margin-right: 26px;
    padding-left: 40px;
    transform: translateY(-2px);
    transition: all .6s ease;
  }

  &__sublist {
    height: auto;
    transition: all .6s ease;
    top: 130px;
    left: 0;
    right: 0;
    font-size: 32px;
    color: var(--color-text);
    visibility: hidden;

  }

  &__subtitle {
    @extend %fontInter;
    text-transform: initial;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text);
  }

  &__item_show {
    cursor: default;

    .technology__title-img {
      transform: rotate(-225deg);
      transition: all .6s ease;
    }

    .technology__container {
      z-index: +3;
      height: 382px;
      transition: all .6s ease;
      border: 1px solid var(--color-text);
    }

    .technology__title-id {
      opacity: 1;
      transition: all .6s ease;
    }

    .technology__sublist {
      z-index: 0;
      visibility: visible;
      transition: all .4s ease;
    }
  }
}

@media only screen and (max-width: 1260px) {
  .technology {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    justify-content: center;

    &__item {
      font-size: 20px;
      height: 102px;
    }

    &__container {
      padding-top: 22px;
      height: 72px;
    }
  }
}

@media only screen and (min-width: 980px) {
  .technology {
    &__title-img {
      display: none;
    }

    &__item:hover {
      cursor: default;

      .technology__title-img {
        transform: rotate(-225deg);
        transition: all .6s ease;
      }

      .technology__container {

        height: 382px;
        transition:  .6s ease;
        border: 1px solid var(--color-text);
        z-index: 3;
      }

      .technology__title-id {
        opacity: 1;
        transition: all .6s ease;
      }

      .technology__sublist {
        visibility: visible;
      }

    }

  }
}

@media only screen and (max-width: 980px) {
  .technology {
    &__subtitle {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .technology {
    &__subtitle {
      font-size: 13px;
    }
  }
}

</style>
