<template>

  <div class="feedback">
    <div class="feedback__wrap">
      <div class="feedback__container-wrap">
        <span class="feedback__title" v-if="langPhrases">{{ langPhrases.page.portfolio.phone }}</span>
        <div class="feedback__container">
          <div class="feedback__contact-wrap" v-if="langPhrases">
            <a :href="`tel:${langPhrases.page.contacts.moscow.phone}`"
               class="feedback__contact feedback__contact_phone">{{
                langPhrases.page.contacts.moscow.phone
              }}</a>
            <a :href="`tel:${langPhrases.page.contacts.moscow.phone}`"
               class="feedback__contact feedback__contact_hidden feedback__contact_phone">{{
                langPhrases.page.contacts.moscow.phone
              }}</a>
          </div>
        </div>
      </div>
      <div class="feedback__container-wrap">
        <span class="feedback__title" v-if="langPhrases">{{ langPhrases.page.portfolio.email }}</span>
        <div class="feedback__container">
          <div class="feedback__contact-wrap" v-if="langPhrases">
            <a :href="`mailto:${langPhrases.page.contacts.moscow.email}`"
               class="feedback__contact feedback__contact_mail">{{ langPhrases.page.contacts.moscow.email }}</a>
            <a :href="`mailto:${langPhrases.page.contacts.moscow.email}`"
               class="feedback__contact feedback__contact_hidden feedback__contact_mail">{{
                langPhrases.page.contacts.moscow.email
              }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="feedback__container feedback__container_btn">
      <div class="feedback__contact-wrap" v-if="langPhrases">
        <a href="mailto:inrotm@scid.ru"
           class="feedback__contact  feedback__contact_btn">{{ langPhrases.page.portfolio.feedback }}</a>
        <a href="mailto:inrotm@scid.ru"
           class="feedback__contact  feedback__contact_hidden  feedback__contact_btn">{{
            langPhrases.page.portfolio.feedback
          }}</a>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Feedback',

  data() {
    return {}
  },
  mounted() {

  },
  computed: {
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

a {
  text-decoration: none;
}

.feedback {
  @extend %padding-left;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 280px;
  //padding-bottom: 60px;

  &__wrap {
    display: flex;
    max-width: 54%;
    width: 100%;
    justify-content: space-between;
  }

  &__title {
    @extend %fontInter;
    display: inline-block;
    margin-bottom: 14px;
    color: var(--color-text);
    opacity: .3;
  }

  &__container-wrap {
    max-width: 302px;
    width: 100%;
    height: 120px;

  }

  &__container {
    max-width: 302px;
    width: 100%;
    height: 80px;
    position: relative;
    overflow: hidden;


  }

  &__container_btn {
    max-width: 572px;
    height: 120px;
    border: 1px solid #393B3E;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0;
    margin-left: auto;
  }

  &__contact-wrap {
    display: flex;
    max-height: 50px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  &__contact {
    @extend %font;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 32px;
    color: var(--color-text);
    width: 100%;
    overflow: hidden;
    min-height: 62px;
    transition: all .4s linear;
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  &__contact_hidden {
    position: absolute;
    top: 180%;
    transition: all .4s linear;

  }

  &__contact_phone {
  }

  &__contact_mail {


  }

  &__contact_btn {
    font-size: 32px;
    justify-content: center;

  }

}

@media only screen and(max-width: 1860px) {
  .feedback {
    flex-wrap: wrap;
    &__wrap {
      max-width: 100%;
    }

    &__container_btn {
      max-width: 100%;

    }
  }
}

@media only screen and (max-width: 1240px) {
  .feedback {
    &__container {
      height: 84px;
    }

    &__title {
      margin-bottom: 0;
    }

    &__contact {
      font-size: 44px;
    }

    &__contact_btn {

      font-size: 32px;
    }
  }
}

@media only screen and (min-width: 980px) {
  .feedback {
    &__container {
      &:hover {
        .feedback__contact {
          top: -100%;
        }

        .feedback__contact_hidden {
          top: 54%;
        }

      }
    }

    &__container_btn {
      transition: all .4s ease;

      &:hover {
        border: 1px solid #F5F5F5;
        transition: all .4s linear;
      }
    }

  }
}

@media only screen and (max-width: 980px) {
  .feedback {
    margin-bottom: 104px;
    display: flex;
    flex-direction: initial;

    &__title {
      margin-bottom: 0;
    }

    &__contact {
      font-size: 50px;
      min-height: 44px;

    }

    &__contact_hidden {
      display: none;
    }

    &__wrap {
      margin-bottom: 60px;
    }

    &__contact_btn {
      font-size: 32px;
    }

    &__container-wrap {
      height: 80px;
    }

  }
}


@media only screen and (max-width: 800px) {
  .feedback {

    &__container {
      height: 100px;
    }

    &__contact {
      font-size: 32px;
    }

    &__contact_btn {
      font-size: 20px;
    }
  }
}

@media only screen and(max-width: 490px) {
  .feedback {
    margin-bottom: 60px;

    &__wrap {
      flex-direction: column;
      margin-bottom: 48px;
    }

    &__contact {
      font-size: 24px;
    }

    &__contact_btn {
      font-size: 16px;
    }

    &__container {
      height: 60px;
      margin-bottom: 12px;
    }

  }
}
</style>
