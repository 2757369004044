<template>
  <div class="technologies">
    <Aside
        @change-theme="changeTheme"/>
    <div class="technologies__container">
      <div class="technologies__title-container">
        <Title/>
      </div>
      <div class="technologies__technology">
        <Technology/>
      </div>
      <TitleNextPage/>
    </div>

  </div>
</template>

<script>
import Aside from '@/components/Aside.vue';
import Technology from '@/components/Technology.vue';
import TitleNextPage from '@/components/TitleNextPage.vue';
import Title from '@/components/Title.vue';


export default {
  name: 'Technologies',
  metaInfo() {
    if(this.langPhrases) {
      return {
        title: this.langPhrases.seo.technologies.title,
        meta: [
          { name: 'description', content: this.langPhrases.seo.technologies.description },
        ]
      }
    }
  },
  components: {
    Aside,
    Technology,
    TitleNextPage,
    Title,
  },
  data() {
    return {
      years: 12,
      employees: 26,
      scroll: false,

    }
  },
  computed: {
    isItemHovered() {
      return this.$store.getters.IS_ITEM_HOVERED;
    },
    isItemsHover() {
      return this.$store.getters.IS_ITEMS_HOVER;
    },
    isItemIndexHovered() {
      return this.$store.getters.IS_ITEM_INDEX_HOVERED;
    },
    theme() {
      return this.$store.getters.THEME;
    },
    activeItemAside() {
      return this.$store.getters.ACTIVE_NAV_LINK;
    },
    list() {
      return this.$store.getters.LIST;
    },
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    }

  },
  watch: {},
  created() {
  },
  mounted() {
    this.scrollTop();
    this.$store.dispatch('clickNavLink', 2);
    this.mouseHover();

  },
  methods: {
    changeTheme(theme) {
      if (theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeBlack)
        document.body.style.setProperty('--background', this.backgroundThemeBlack)
      } else if (!theme) {
        document.body.style.setProperty('--color-text', this.colorTextThemeWhite)
        document.body.style.setProperty('--background', this.backgroundThemeWhite)
      }
    },
    onHover() {
      document.querySelector('.cursor-dot').classList.add('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.add('cursor_hover');

    },
    offHover() {
      document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
      document.querySelector('.cursor').classList.remove('cursor_hover');
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    mouseHover() {
      if (!this.isItemHovered) {
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover');
        document.querySelector('.cursor-dot').classList.remove('cursor-dot_hover_page_next');
        document.querySelector('.cursor').classList.remove('cursor_hover')
      } else if (this.isItemHovered) {
        document.querySelector('.cursor-dot').classList.add('cursor-dot_hover')
        document.querySelector('.cursor').classList.add('cursor_hover')

      }
    },
    handleScroll() {
      if (window.scrollY > 0) {
        this.scroll = true;
      }
    },
    clickNavLink(index) {
      this.$store.dispatch('mouseOut', null)
      this.$store.dispatch('clickNavLink', index)
    },


  },

}
</script>
<style lang="scss" scoped>
@import '../assets/styles/vars.scss';



.technologies {
  display: flex;
  background-color: var(--background);

  &__container {
    @extend %padding-right;
    display: flex;
    flex-direction: column;
    width: 100%;

  }

  &__title-container {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 108px;
  }

  &__technology {
    @extend %padding-left;
    margin-bottom: 228px;
  }

  &__title {
    @extend %padding-left;
    border-left: 1px solid #FFFFFF;
    display: flex;

    & span {
      @extend %font;
      display: inline-block;
      margin: 0;
      font-size: 320px;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--color-text);
      transform: translateY(68px);

    }
  }


  &__next-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    @extend %padding-right;
    @extend %padding-left;
    padding-bottom: 52px;

    transition: all .6s ease;
    overflow: hidden;
    max-height: 280px;
    height: 100%;

    & a {
      position: relative;
      height: 100%;
      //  ====
      min-height: 350px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

    }

    &:hover {
      .technologies__next {
        opacity: 1;
        transition: all .6s ease;

        & span {
          opacity: 1;
        }
      }

      .technologies__next_visible {
        top: -100%;
      }

      .technologies__next_hidden {
        top: 0;
      }
    }
  }

  &__next {
    @extend %font;
    display: flex;
    align-items: baseline;
    font-size: 320px;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-text);
    transition: all .6s ease;
    width: 100%;
    position: absolute;
    opacity: .05;

    & p {
      margin: 0;
      padding-top: 12px;
    }

    & span {
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      opacity: .3;
      margin-right: 0;
      margin-left: auto;
    }

  }

  &__next_visible {
    top: 0;
    left: 0;
    transition: all .6s ease;
  }

  &__next_hidden {
    top: 100%;
    left: 0;

  }
}
@media only screen and (max-width: 1280px){
  .technologies{
    &__technology {
      margin-bottom: 122px;
    }
  }
}
</style>
