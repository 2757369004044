<template>
  <div class="btns">
    <div class="btns__item "
         :class=" {'btns__item_active': item.code == activeBtn} "
         v-for="item in casesBtns"
         :key="item.id"
         @click="clikBtn(item)">
      <div class="btns__item-container">
        <div class="btns__item-title btns__item-title_visible"><img class="btns__item-img"
                                                                    :src="item.img"
                                                                    v-show="item.img "
                                                                    alt="лого">
          <p v-if="langPhrases">{{ langPhrases.page.portfolio.filter[item.code] }}</p></div>
        <div class="btns__item-title btns__item-title_hidden"><img class="btns__item-img"
                                                                   :src="item.img"
                                                                   v-show="item.img "
                                                                   alt="лого">
          <p v-if="langPhrases">{{ langPhrases.page.portfolio.filter[item.code] }}</p></div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'BtnList',
  components: {},

  data() {
    return {
      casesBtns: [
        {
          id: 1,
          code: 'services',
        }, {
          id: 2,
          code: 'categories',
        }
      ],
      serviceId: 0,
      categoryId: 0,

    }
  }, props: {
    activeBtn: String
  },

  computed: {
    langPhrases() {
      return this.$store.getters.LANG_PHRASES
    }
  },
  watch: {},
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  activated() {
  },
  deactivated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  methods: {
    clikBtn(item) {
      this.$emit('setActiveBtn', item.code);
      // this.$emit('setServiceId', 0);
      // this.$emit('setCategoryId', 0);
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.btns {
  @extend %padding-left;
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 120px;
    border: 1px solid #393B3E;
    background-color: var(--background);
    transition: all .4s linear;


  }

  &__item-img {
    width: 42px;
    height: 42px;
    margin-right: 16px;
    transform: translateY(-2px);
  }

  &__item-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: 48px;
    width: 100%;
  }

  &__item-title {
    @extend %font;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 32px;
    font-style: normal;
    font-weight: bold;
    color: var(--color-text);
    transition: all .4s ease;
    width: 100%;
    margin: 0;
    cursor: default;
    position: absolute;

    & p {
      margin: 0;
    }
  }

  &__item-title_visible {
    top: 22%;
    left: 0;
    transition: all .4s ease;
  }

  &__item-title_hidden {
    top: 100%;
    left: 0;
    transition: all .4s ease;

  }

  &__item_active {
    filter: invert(100%);
  }
}

@media only screen and(max-width: 1360px) {
  .btns {
    &__item {
      height: 60px;
    }

    &__item-img {
      width: 24px;
      height: 24px;
    }

    &__item-container {
      height: 28px;
    }

    &__item-title {
      font-size: 20px;
    }

  }
}

@media only screen and(min-width: 980px) {
  .btns {
    &__item {

      &:hover {
        border: 1px solid var(--color-text);

        .btns__item-title_hidden {
          transition: all .4s linear;
          top: 22%;
        }

        .btns__item-title_visible {
          top: -100%;
          transition: all .4s linear;
        }
      }
    }
  }
}

@media only screen and(max-width: 490px) {
  .btns {
    &__item {
      height: 40px;
    }

    &__item-title {
      font-size: 16px;
    }

  }
}


</style>
